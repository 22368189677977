export function hasWebGL2() {
    const element = document.createElement("canvas");
    const gl = element.getContext("webgl2");
    element.remove();

    return gl ? true : false;
}

export function isMobileTgPlatform() {
    return ["android", "android_x", "ios"].includes(Telegram.WebApp.platform);
}
