// import BugsnagPerformance from "@bugsnag/browser-performance";
// import Bugsnag from "@bugsnag/js";
// import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import firebaseConfig from "./lib/firebaseConfig.json";

export function attachAnalytics() {
    const app = initializeApp(firebaseConfig);

    // Bugsnag.start({
    //     apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    //     releaseStage: import.meta.env.MODE,
    //     context: "unknown",
    //     user: {
    //         id: Telegram.WebApp.initDataUnsafe.user?.id?.toString()
    //     },
    //     plugins: [new BugsnagPluginReact()]
    // });

    // BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY });

    return getAnalytics(app);
}
