import { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Preloader from "./ui/preloader/Preloader";
import { useIsCurrentChainOrWalletSupported } from "./hooks";
import { useVersionContext } from "./VersionProvider";
import Story from "./assets/story.png";
import { useBackendTonAuth } from "./hooks/useBackendTonAuth";
import { useTonConnectModal, useTonConnectUI } from "@tonconnect/ui-react";
import { logEvent } from "firebase/analytics";
import { ga } from ".";
const App = () => {
  const version = useVersionContext();
  const { unityProvider, addEventListener, removeEventListener, isLoaded, loadingProgression, sendMessage } =
    useUnityContext({
      loaderUrl: "unity-build/GetRich.loader.js?v=" + version,
      dataUrl: "unity-build/GetRich.data.unityweb?v=" + version,
      frameworkUrl: "unity-build/GetRich.framework.js.unityweb?v=" + version,
      codeUrl: "unity-build/GetRich.wasm.unityweb?v=" + version,
      streamingAssetsUrl: "unity-build/StreamingAssets",
      companyName: "PlatinumGames",
      productName: "GetRich",
      productVersion: "main-4016-10160"
    });

  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);
  const [tonConnectUI] = useTonConnectUI();
  const [jwt, setJwt] = useState<string>("");
  const { open: openTonConnectModal } = useTonConnectModal();

  const { isCorrectChainConnected } = useIsCurrentChainOrWalletSupported();
  useBackendTonAuth(sendMessage, jwt, isCorrectChainConnected, isLoaded);

  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`);
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  useEffect(() => {
    addEventListener("GetInitData", () => {
      sendMessage("front_data_handler", "SetInitData", window.Telegram.WebApp.initData);
    });

    addEventListener("AcceptRef", () => {
      const refCode = window.Telegram.WebApp.initDataUnsafe.start_param;

      fetch(import.meta.env.VITE_TASK_SERVICE_URL + `user/ref/accept?${window.Telegram.WebApp.initData}`, {
        method: "POST",
        body: JSON.stringify({
          refCode: refCode
        })
      }).catch((err) => console.error(`User ref fetch failed: ${err}`));
    });

    addEventListener("CopyTextToClipboard", (text) => {
      if (text) {
        navigator.clipboard
          .writeText(text.toString())
          .then(() => {
            console.log("copied to clipboard");
          })
          .catch((err) => {
            console.error(`error on copy link: ${err}`);
          });
      } else {
        console.error("CopyTextToClipboard: text parameter is undefined");
      }
    });

    addEventListener("RedirectToLink", (link) => {
      if (link) {
        window.open(link.toString(), "_blank", "noopener,noreferrer");
      } else {
        console.error("RedirectToLink: link parameter is undefined");
      }
    });

    addEventListener("StartQuest", (questId, link) => {
      (async () => {
        try {
          await fetch(import.meta.env.VITE_TASK_SERVICE_URL + `task/start?${window.Telegram.WebApp.initData}`, {
            method: "POST",
            body: JSON.stringify({
              taskId: questId
            })
          });
          if (link) window.open(link.toString(), "_blank", "noopener,noreferrer");
          else console.error("StartQuest: link parameter is missing");
        } catch (err) {
          console.error(`Task start fetch failed: ${err}`);
        }
      })();
    });

    addEventListener("ClaimQuest", (questId) => {
      fetch(import.meta.env.VITE_TASK_SERVICE_URL + `task/claim?${window.Telegram.WebApp.initData}`, {
        method: "POST",
        body: JSON.stringify({
          taskId: questId
        })
      }).catch((err) => console.error(`Task claim fetch failed: ${err}`));
    });

    addEventListener("WelcomeBonus", (jwt) => {
      fetch(import.meta.env.VITE_SERVER_URL + `/user/welcome`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }).catch((err) => console.error(`Welcome fetch failed: ${err}`));
    });

    addEventListener("PostStory", () => {
      Telegram.WebApp.shareToStory(Story, {
        text:
          "Текст сториса" +
          (Telegram.WebApp.initDataUnsafe.user?.is_premium ? "" : `Сюда можно добавить ссылку, если нет према`),
        widget_link: {
          url: "https://ссылка_видна_только_премам",
          name: "Текст ссылки"
        }
      });
    });

    addEventListener("GetVersion", () => {
      return version;
    });

    addEventListener("PurchaseBooster", (boosterId) => {
      fetch(import.meta.env.VITE_SERVER_URL + `/user/boosters/purchase?${window.Telegram.WebApp.initData}`, {
        method: "POST",
        body: JSON.stringify({
          boosterId
        })
      })
        .then(async (res) => sendMessage("front_data_handler", "PurchaseBooster", JSON.stringify(await res.json())))
        .catch((err) => console.error(`Purchase booster fetch failed: ${err}`));
    });

    addEventListener("UpgradeBooster", (type, boosterId) => {
      fetch(import.meta.env.VITE_SERVER_URL + `/user/boosters/upgrade?${window.Telegram.WebApp.initData}`, {
        method: "POST",
        body: JSON.stringify({
          type,
          boosterId
        })
      })
        .then(async (res) => sendMessage("front_data_handler", "UpgradeBooster", JSON.stringify(await res.json())))
        .catch((err) => console.error(`Upgrade booster fetch failed: ${err}`));
    });

    addEventListener("SelectBooster", (boosterId) => {
      fetch(import.meta.env.VITE_SERVER_URL + `/user/boosters/select?${window.Telegram.WebApp.initData}`, {
        method: "POST",
        body: JSON.stringify({
          boosterId
        })
      })
        .then(async (res) => sendMessage("front_data_handler", "SelectBooster", JSON.stringify(await res.json())))
        .catch((err) => console.error(`Select booster fetch failed: ${err}`));
    });

    addEventListener("ConnectWallet", (jwt) => {
      setJwt(jwt as string);
      openTonConnectModal();
    });

    addEventListener("DisconnectWallet", () => {
      tonConnectUI.disconnect();
    });

    addEventListener("SendEvent", (type, data) => {
      console.log(`SendEvent: sending ${type}` + data ? ` with data ${data}` : "");
      if (!type) {
        console.error("SendEvent: type parameter is missing");
        return;
      }

      logEvent(ga, type.toString(), data ? JSON.parse(data.toString()) : undefined);
      console.log(`SendEvent: ${type} event is sent`);
    });

    return () => {
      removeEventListener("GetInitData", () => {});
      removeEventListener("AcceptRef", () => {});
      removeEventListener("CopyTextToClipboard", () => {});
      removeEventListener("RedirectToLink", () => {});
      removeEventListener("ClaimQuest", () => {});
      removeEventListener("StartQuest", () => {});
      removeEventListener("WelcomeBonus", () => {});
      removeEventListener("GetVersion", () => {});
      removeEventListener("PostStory", () => {});
      removeEventListener("PurchaseBooster", () => {});
      removeEventListener("UpgradeBooster", () => {});
      removeEventListener("SelectBooster", () => {});
      removeEventListener("ConnectWallet", () => {});
      removeEventListener("DisconnectWallet", () => {});
      removeEventListener("SendEvent", () => {});
    };
  }, [addEventListener, removeEventListener, isLoaded]);

  return (
    <>
      {!isLoaded && <Preloader progress={loadingProgression} />}
      <Unity devicePixelRatio={devicePixelRatio} matchWebGLToCanvasSize={true} unityProvider={unityProvider} />
      {/* <TapScreen sendMessage={sendMessage} isLoaded={isLoaded} /> */}
    </>
  );
};

export default App;
