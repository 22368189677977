// import Bugsnag from "@bugsnag/js";
// import React from "react";
import eruda from "eruda";
import { createRoot } from "react-dom/client";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App.tsx";
import { VersionProvider } from "./VersionProvider.tsx";
import { attachAnalytics } from "./analytics.ts";
import "./index.css";
import { hasWebGL2, isMobileTgPlatform } from "./lib/utils.ts";
import LockScreen from "./ui/lock-screen/LockScreen.tsx";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

export const ga = attachAnalytics();
eruda.init();

// if (
//   window.indexedDB != null &&
//   localStorage.getItem("build-version") !== version
// ) {
//   window.indexedDB.deleteDatabase("/idbfs");
//   localStorage.setItem("build-version", version);
// }

if (!hasWebGL2()) {
  toast("WebGL2 is unsupported", {
    style: {
      textAlign: "center",
      backgroundColor: "#101010",
      fontWeight: 600
    },
    autoClose: 3000,
    position: "top-center",
    hideProgressBar: true,
    closeButton: false,
    transition: Slide
  });
}

const isAllowedPlatform = import.meta.env.VITE_ALLOW_ALL_PLATFORMS === "true" || isMobileTgPlatform();
//const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

createRoot(document.getElementById("root")!).render(
  isAllowedPlatform ? (
    //<ErrorBoundary>
    <TonConnectUIProvider manifestUrl={`https://dev2.getrich.miniapp.click/tonconnect-manifest.json`}>
      <VersionProvider>
        <App />
        <ToastContainer />
      </VersionProvider>
    </TonConnectUIProvider>
  ) : (
    //</ErrorBoundary>
    <LockScreen />
  )
);
