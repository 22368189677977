//import Bugsnag from "@bugsnag/js";
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";

const VersionContext = createContext("1");

export const useVersionContext = () => {
  return useContext(VersionContext);
};

export const VersionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [version, setVersion] = useState("1");
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    fetchVersion();
  }, [setVersion]);

  async function fetchVersion() {
    const path = location.origin + location.pathname + "unity-build/version.number";
    try {
      const res = await fetch(path, {
        headers: {
          "Cache-Control": "no-cache"
        }
      });
      const str = await res.text();

      if (str.substring(0, str.indexOf("\n")).trim() === "<!DOCTYPE html>") {
        throw new Error("Invalid file format");
      }

      //Bugsnag.setContext(str);
      setVersion(str.substring(str.lastIndexOf("-") + 1).trim());
    } catch (err) {
      console.log(`Failed to fetch ${path}`, err);
    } finally {
      setIsFetched(true);
    }
  }

  return <VersionContext.Provider value={version}>{isFetched && children}</VersionContext.Provider>;
};
