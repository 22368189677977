import { Address } from '@ton/core';
import { useIsConnectionRestored, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters';

export function useBackendTonAuth(
  sendMessage: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void,
  jwt: string,
  isCorrectChainConnected: boolean,
  isUnityInitialized: boolean
) {
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();

  const [payload, setPayload] = useState<string | null>(null);

  const errorToast = () =>
    toast.error('Wrong wallet address!', {
      hideProgressBar: true,
      toastId: 'error1',
    });

  const postAuth = async () => {
    try {
      if (wallet && isCorrectChainConnected) {
        if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
          const res = await fetch(import.meta.env.VITE_SERVER_URL + `/user/wallet`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              connectItems: wallet.connectItems.tonProof,
              account: wallet.account,
            }),
          });

          if (!(await res.json()).result) {
            errorToast();
            sendMessage('front_data_handler', 'WalletStatus', JSON.stringify({ address: null, success: false }));
            tonConnectUI.disconnect();
            return;
          }

          sendMessage('front_data_handler', 'WalletStatus', JSON.stringify({ address: Address.normalize(wallet.account.address), success: true }));
        } else {
          sendMessage('front_data_handler', 'WalletStatus', JSON.stringify({ address: Address.normalize(wallet.account.address), success: true }));
        }
      }
    } catch (error) {
      console.error(error);
      sendMessage('front_data_handler', 'WalletStatus', JSON.stringify({ address: null, success: false }));
      tonConnectUI.disconnect();
      errorToast();
    }
  };

  useEffect(() => {
    const getPayload = async () => {
      const res = await fetch(import.meta.env.VITE_SERVER_URL + `/payload?${window.Telegram.WebApp.initData}`);
      setPayload((await res.json()).result.payload);
    };

    getPayload();
  }, []);

  useEffect(() => {
    if (!isConnectionRestored || !isUnityInitialized) {
      return;
    }

    if (!wallet) {
      if (!payload) {
        return;
      }
      tonConnectUI.setConnectRequestParameters({
        state: 'ready',
        value: {
          tonProof: payload,
        },
      });
      sendMessage('front_data_handler', 'WalletStatus', JSON.stringify({ address: null, success: false }));
      return;
    }

    postAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, isConnectionRestored, isUnityInitialized, payload, jwt, isCorrectChainConnected]);
}
