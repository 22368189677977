import { CHAIN, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const useIsCurrentChainOrWalletSupported = () => {
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const [isCorrectChainConnected, setIsCorrectChainConnected] = useState(false);
  const errorToast = () =>
    toast.error('Incorrect wallet network!', {
      hideProgressBar: true,
      toastId: 'error1',
    });

  const checkIfChainOrWalletIsUnsupported = useCallback(async () => {
    if (!wallet) {
      return;
    }

    const chainId = wallet.account.chain;

    const currentChain = CHAIN.MAINNET; //import.meta.env.VITE_NETWORK === 'testnet' ? CHAIN.TESTNET : CHAIN.MAINNET;

    if (chainId && chainId !== currentChain) {
      tonConnectUI.disconnect();
      errorToast();

      return;
    }

    setIsCorrectChainConnected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    checkIfChainOrWalletIsUnsupported();
  }, [checkIfChainOrWalletIsUnsupported]);

  return { isCorrectChainConnected };
};
