import style from './Preloader.module.css';
import Splash from '../../assets/preloader.webp';

interface PrelaoderProps {
  progress: number;
}

const Preloader = ({ progress }: PrelaoderProps) => {
  const percent = Math.round(progress * 100);

  return (
    <div className={style.container}>
      <img src={Splash} alt="" />
      <div className={style.footer}>
        <div className={style.progressBar}>
          <div className={style.filler} style={{ width: `${percent}%` }} />
        </div>
        <span>The first launch can be long</span>
      </div>

      <div className={style.gradient} />
    </div>
  );
};

export default Preloader;
